import { Dispatch, SetStateAction } from 'react'

import { GetVariable, GetVariableValue, SetLocalVariables } from 'hooks'
import {
  BrandingFontStyle,
  BrandingThemeType,
  ComponentAlignment,
  ComponentType,
  ComponentsDirection,
  ListStyle,
  Screen,
  ScreenComponent,
  createButton,
  createCalendar,
  createCarousel,
  createImage,
  createList,
  createMap,
  createNotificationsSettings,
  createProgressIndicator,
  createSlider,
  createSpacer,
  createText,
  createTextInput,
  createThemePicker,
  createToggle,
  createVideo,
  getCSSTextAlignment,
  getComponentTypeById,
  getElementIdFromConfig,
  isParentComponent,
  listGalleryHandler,
  screenHeight,
  screenWidth,
  setColors,
} from 'utils'

export const setInnerModeEvent = 'setInnerModeEvent'
export const screenComponentClassName = 'screenComponent'

export const createElement = async (
  setSubComponents: (element: HTMLElement, component: ScreenComponent) => void,
  component: ScreenComponent,
  parent: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  theme: BrandingThemeType,
  compression: number,
  firstChildOfScreen: boolean,
  onlyChild: boolean,
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  setLocalVariables: SetLocalVariables,
  setScroll?: (func: () => void) => void,
  onClickComponent?: (componentId: string, subComponentId: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>,
  setMovableComponent?: (config: ScreenComponent) => void,
  innerModeComponentId?: string
) => {
  const fromVB = !!(onClickComponent && setMovableComponent && setHoveredId && setScroll)
  const parentComponentType = getComponentTypeById(parent.id)
  const {
    id,
    visible,
    name,
    backgroundColor,
    borderColor,
    borderWidth,
    cornersRadius,
    font,
    textColor,
    textAlignment,
    componentsDirection,
    reversed,
    contentPadding,
    componentType,
    componentsSpacing,
    margins,
    width,
    height,
    // product,
    componentAlignment,
    backgroundGradient,
    backgroundPattern,
    widthHeightRatio,
    localVariables,
    listStyle,
    value,
  } = component

  const element = document.createElement(
    componentType === ComponentType.textInput || componentType === ComponentType.datePicker ? 'form' : 'div'
  )
  parent.appendChild(element)

  const elementId = getElementIdFromConfig(component)
  element.id = elementId
  element.className = screenComponentClassName
  element.style.position = 'relative'
  element.style.fontFamily = fontFamily
  element.style.boxSizing = 'border-box'

  if (localVariables) {
    await setLocalVariables(getVariableValue, localVariables)
  }

  if (visible) {
    const notVisibleValue = (await getVariableValue({ ...visible, booleanConstant: visible.constant })) === false
    if (notVisibleValue) {
      element.hidden = true
    } else {
      element.removeAttribute('hidden')
    }
  }

  if (fromVB) {
    element.ondblclick = e => {
      e.stopPropagation()
      if (componentType === ComponentType.carousel || componentType === ComponentType.list) {
        document.dispatchEvent(new Event(setInnerModeEvent))
      }
    }
    let timer: NodeJS.Timeout | null = null
    const componentCopy = JSON.parse(JSON.stringify(component)) as ScreenComponent
    delete componentCopy.listId
    delete componentCopy.indexInList
    element.onmousedown = e => {
      e.stopPropagation()
      onClickComponent(innerModeComponentId || id, innerModeComponentId ? id : '')
      timer = setTimeout(() => setMovableComponent(componentCopy), 600)
    }
    element.onmouseup = () => timer && clearTimeout(timer)
    element.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(id)
    }
    element.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
    if (
      (firstChildOfScreen &&
        onlyChild &&
        (componentType === ComponentType.scroll || componentType === ComponentType.list)) ||
      (name === 'Messages' && componentType === ComponentType.list)
    ) {
      element.onscroll = () => {
        const scrollTop = element.scrollTop
        setScroll(() => {
          const scrollElement = document.getElementById(elementId)
          if (scrollElement) {
            scrollElement.scrollTop = scrollTop
          }
        })
      }
    }
  }

  if (!fromVB) {
    const { x, y, height, width } = element.getBoundingClientRect()
    if (x > screenWidth || y > screenHeight || x + width < 0 || y + height < 0) {
      return element
    }
  }

  if (parentComponentType === ComponentType.overlay) {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        element.style.bottom = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.leading:
        element.style.position = 'absolute'
        element.style.left = '0'
        break
      case ComponentAlignment.trailing:
        element.style.position = 'absolute'
        element.style.right = '0'
        break
      case ComponentAlignment.top:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.topLeading:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '0'
        break
      case ComponentAlignment.topCenter:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.left = '50%'
        element.style.transform = 'translateX(-50%)'
        break
      case ComponentAlignment.topTrailing:
        element.style.position = 'absolute'
        element.style.top = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.centerLeading:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.left = '0'
        element.style.transform = 'translateY(-50%)'
        break
      case ComponentAlignment.center:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.left = '50%'
        element.style.transform = 'translate(-50%, -50%)'
        break
      case ComponentAlignment.centerTrailing:
        element.style.position = 'absolute'
        element.style.top = '50%'
        element.style.right = '0'
        element.style.transform = 'translateY(-50%)'
        break
      case ComponentAlignment.bottom:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '0'
        element.style.right = '0'
        break
      case ComponentAlignment.bottomLeading:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '0'
        break
      case ComponentAlignment.bottomCenter:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.left = '50%'
        element.style.transform = 'translateX(-50%)'
        break
      case ComponentAlignment.bottomTrailing:
        element.style.position = 'absolute'
        element.style.bottom = '0'
        element.style.right = '0'
        break
    }
  } else {
    switch (componentAlignment) {
      case ComponentAlignment.fill:
        if (
          parentComponentType !== ComponentType.scroll &&
          parentComponentType !== ComponentType.list &&
          onlyChild &&
          (isParentComponent(componentType) || componentType === ComponentType.map)
        ) {
          element.style.width = `calc(100% - (${
            (await getVariableValue({ numberConstant: margins?.leading })) || 0
          }px + ${(await getVariableValue({ numberConstant: margins?.trailing })) || 0}px))`
          element.style.height = `calc(100% - (${(await getVariableValue({ numberConstant: margins?.top })) || 0}px + ${
            (await getVariableValue({ numberConstant: margins?.bottom })) || 0
          }px))`
        }
        element.style.alignSelf = 'stretch'
        break
      case ComponentAlignment.start:
        element.style.alignSelf = 'flex-start'
        break
      case ComponentAlignment.end:
        element.style.alignSelf = 'flex-end'
        break
      case ComponentAlignment.center:
        element.style.alignSelf = 'center'
        break
    }
  }

  if (width) {
    const widthValue = await getVariableValue({ ...width, numberConstant: width?.constant })
    element.style.width = `${widthValue}px`
    element.style.flexShrink = '0'
  }

  if (height) {
    const heightValue = await getVariableValue({ ...height, numberConstant: height?.constant })
    element.style.height = `${heightValue}px`
    element.style.flexShrink = '0'
  }

  if (widthHeightRatio) {
    const widthHeightRatioValue = await getVariableValue({
      ...widthHeightRatio,
      numberConstant: widthHeightRatio?.constant,
    })
    const { width, height } = element.getBoundingClientRect()
    if (!height && width) {
      const heightValue = width / compression / widthHeightRatioValue
      element.style.height = `${heightValue}px`
    }
    if (!width && height) {
      const widthValue = (height / compression) * widthHeightRatioValue
      element.style.width = `${widthValue}px`
    }
    element.style.flexShrink = '0'
  }

  if (
    !width &&
    !widthHeightRatio &&
    parentComponentType === ComponentType.stack &&
    parent.style.flexDirection === 'row'
  ) {
    element.style.flexGrow = '1'
  }

  if (componentType === ComponentType.text) {
    await createText(element, component, getVariableValue)
  }

  if (componentType === ComponentType.button) {
    await createButton(fromVB, element, component, getVariableValue)
  }

  if (componentType === ComponentType.image) {
    await createImage(fromVB, element, component, getVariableValue)
  }

  if (componentType === ComponentType.videoPlayer) {
    await createVideo(element, component, getVariableValue)
  }

  if (componentType === ComponentType.themePicker) {
    await createThemePicker(element, getVariableValue, theme)
  }

  if (componentType === ComponentType.notificationsSettings) {
    await createNotificationsSettings(element, getVariableValue)
  }

  if (componentType === ComponentType.carousel) {
    await createCarousel(element, component, getVariableValue)
  }

  if (componentType === ComponentType.view) {
    if (!height && !width && !widthHeightRatio) {
      createSpacer(fromVB, element, false)
    }
  }

  if (componentType === ComponentType.spacer) {
    createSpacer(fromVB, element, !!(height || width))
  }

  if (componentType === ComponentType.scroll || componentType === ComponentType.list) {
    if (componentsDirection !== ComponentsDirection.horizontal) {
      element.style.flexGrow = '1'
    }
    element.style.overflow =
      componentsDirection === ComponentsDirection.all
        ? 'auto'
        : componentsDirection === ComponentsDirection.vertical
        ? 'hidden auto'
        : 'auto hidden'
  }

  if (componentType === ComponentType.map) {
    await createMap(element, component, screenConfig, getVariable, getVariableValue)
  }

  if (componentType === ComponentType.calendar) {
    await createCalendar(element, component, fontStyles, screenConfig, getVariable, getVariableValue)
  }

  if (componentType === ComponentType.slider) {
    await createSlider(element, component, getVariableValue)
  }

  if (componentType === ComponentType.toggle) {
    await createToggle(element, component, getVariableValue)
  }

  if (componentType === ComponentType.progressIndicator) {
    await createProgressIndicator(element, component, getVariableValue)
  }

  if (componentsDirection) {
    if (componentsDirection === ComponentsDirection.all) {
      const componentsSpacingValue = await getVariableValue({
        ...componentsSpacing,
        numberConstant: componentsSpacing?.constant,
      })
      element.style.display = 'grid'
      const columnWidth = `calc(50% - ${componentsSpacingValue / 2}px)`
      element.style.gridTemplateColumns = `${columnWidth} ${columnWidth}`
    } else {
      const reversedValue = await getVariableValue({ ...reversed, booleanConstant: reversed?.constant })
      element.style.display = 'flex'
      element.style.flexDirection = `${componentsDirection === ComponentsDirection.vertical ? 'column' : 'row'}${
        reversedValue ? '-reverse' : ''
      }`
    }
  }

  if (textAlignment) {
    element.style.textAlign = getCSSTextAlignment(textAlignment)
  }

  const currentFont = fontStyles.find(el => el.styleName === font?.slice(1))?.ios
  if (currentFont) {
    element.style.fontSize = `${currentFont.fontSize}px`
    element.style.fontWeight = `${currentFont.fontWeight}`
    element.style.fontStyle = currentFont.fontStyle
    element.style.letterSpacing = `${currentFont.letterSpacing}px`
    element.style.lineHeight = `${currentFont.lineHeight}%`
  }

  await setColors(
    element,
    getVariableValue,
    backgroundColor,
    backgroundGradient,
    backgroundPattern,
    textColor,
    borderColor
  )

  if (borderWidth) {
    const borderWidthValue = await getVariableValue({ ...borderWidth, numberConstant: borderWidth?.constant })
    element.style.borderWidth = `${borderWidthValue}px`
    element.style.borderStyle = 'solid'
  }

  if (contentPadding) {
    const { top, trailing, bottom, leading } = contentPadding.constant || {}
    element.style.padding = `${(await getVariableValue({ numberConstant: top })) || 0}px ${
      (await getVariableValue({ numberConstant: trailing })) || 0
    }px ${(await getVariableValue({ numberConstant: bottom })) || 0}px ${
      (await getVariableValue({ numberConstant: leading })) || 0
    }px`
  }

  if (margins) {
    const { top, trailing, bottom, leading } = margins
    element.style.margin = `${(await getVariableValue({ numberConstant: top })) || 0}px ${
      (await getVariableValue({ numberConstant: trailing })) || 0
    }px ${(await getVariableValue({ numberConstant: bottom })) || 0}px ${
      (await getVariableValue({ numberConstant: leading })) || 0
    }px`
  }

  if (componentsSpacing) {
    const componentsSpacingValue = await getVariableValue({
      ...componentsSpacing,
      numberConstant: componentsSpacing?.constant,
    })
    element.style.gap = `${componentsSpacingValue}px`
  }

  if (cornersRadius) {
    if (isParentComponent(componentType) || componentType === ComponentType.map) {
      element.style.overflow = 'hidden'
      if (
        parentComponentType === ComponentType.scroll ||
        parentComponentType === ComponentType.list ||
        parent.style.flexDirection === 'column'
      ) {
        element.style.flexShrink = '0'
      }
    }
    const cornersRadiusValue = await getVariableValue({ ...cornersRadius, numberConstant: cornersRadius?.constant })
    element.style.borderRadius = `${cornersRadiusValue}px`
  }

  if (componentType === ComponentType.textInput || componentType === ComponentType.datePicker) {
    await createTextInput(element, component, fontStyles, componentType === ComponentType.datePicker, getVariableValue)
  }

  // if (componentType === ComponentType.product) {
  //   const productValue = product?.source
  //     ? await getVariableValue(product.source)
  //     : product?.constant;
  //   if (productValue) {
  //     const product = products.find((el) => el.id === productValue);
  //     if (product) {
  //       await createProduct(
  //         element,
  //         component,
  //         product,
  //         getPxValue,
  //         getVariableValue
  //       );
  //     }
  //   }
  // }

  if (componentType === ComponentType.list) {
    await createList(element, component, screenConfig, getVariable, getVariableValue, setSubComponents, 30)
    if (listStyle === ListStyle.gallery) {
      await listGalleryHandler(element, getVariableValue, value, reversed, name)
    }
  } else {
    await setSubComponents(element, component)
  }
}
