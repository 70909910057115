import firebase from 'services/firebase'

import { TableQueryFilterOperator } from './index'

export const operators: {
  [key in TableQueryFilterOperator]: firebase.firestore.WhereFilterOp
} = {
  [TableQueryFilterOperator.equal]: '==',
  [TableQueryFilterOperator.notEqual]: '!=',
  [TableQueryFilterOperator.less]: '<',
  [TableQueryFilterOperator.lessOrEqual]: '<=',
  [TableQueryFilterOperator.greater]: '>',
  [TableQueryFilterOperator.greaterOrEqual]: '>=',
  [TableQueryFilterOperator.contains]: 'array-contains',
  [TableQueryFilterOperator.matches]: '==',
}

export const needOrderByOperators = [
  operators[TableQueryFilterOperator.notEqual],
  operators[TableQueryFilterOperator.less],
  operators[TableQueryFilterOperator.lessOrEqual],
  operators[TableQueryFilterOperator.greater],
  operators[TableQueryFilterOperator.greaterOrEqual],
]
