import { Box } from '@mui/material'
import React from 'react'

import { CommonPicker, IPickerItem, Icon, Name } from 'components'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { FieldOrder, TableQueryOrder, VariableSource } from 'utils'

const tableQueryOrderValues = Object.values(TableQueryOrder).map(el => ({ value: el, label: el }))

export const isOrderedNotValid = (ordered?: FieldOrder[]) => !!ordered?.find(el => !el.field || !el.order)

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  currentVariableCollectionFieldsValues: IPickerItem[]
}

export const Ordered: React.FC<Props> = ({
  currentVariable,
  setCurrentVariable,
  currentVariableCollectionFieldsValues,
}) => {
  const { query } = currentVariable

  const onAdd = () =>
    currentVariableCollectionFieldsValues.length &&
    setCurrentVariable(currentVariable =>
      currentVariable
        ? {
            ...currentVariable,
            query: {
              ...currentVariable.query,
              ordered: [...(currentVariable.query?.ordered || []), {} as FieldOrder],
            },
          }
        : null
    )

  const onRemove = (index: number) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.query?.ordered?.splice(index, 1)
      if (copy?.query && !copy.query.ordered?.length) {
        delete copy.query.ordered
      }
      if (copy?.query && !copy.query.filters && !copy.query.ordered) {
        delete copy.query
      }
      return copy
    })

  const onChange = (index: number, order: FieldOrder | { field?: string; order?: TableQueryOrder }) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.query?.ordered?.splice(index, 1, order as FieldOrder)
      return copy
    })

  return (
    <SubWrapper small noBorderBottom title="Ordered" onAdd={onAdd} canHide={!!query?.ordered?.length} childrenFor="">
      {query?.ordered && (
        <Box display="flex" flexDirection="column" gap="12px">
          {query.ordered.map((el, i) => (
            <RightSidebarItem key={`${el.field}${i}`} small title="">
              <SubWrapper
                small
                title="ORDER"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                <SettingField
                  insidePicker
                  subLabel="Field"
                  value={el.field}
                  small
                  optionsContainer={
                    <CommonPicker
                      title="Fields"
                      value={el.field}
                      onChange={value => onChange(i, { field: value })}
                      values={currentVariableCollectionFieldsValues}
                      close={() => {}}
                    />
                  }
                />
                <SettingField
                  insidePicker
                  subLabel="Order"
                  value={el.order}
                  small
                  optionsContainer={
                    <CommonPicker
                      title="Orders"
                      value={el.order}
                      onChange={value => onChange(i, { ...el, order: value })}
                      values={tableQueryOrderValues}
                      close={() => {}}
                    />
                  }
                />
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
