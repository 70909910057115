import React from 'react'

import { CustomPicker, Variable } from 'components'
import { LocalVariable, Screen, ValueType, VariableSource } from 'utils'
import { OptionsContainer } from './components'

interface Props {
  close: () => void
  dateTime?: boolean
  value?: string
  onChange: (val: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const DatePicker: React.FC<Props> = ({
  close,
  dateTime,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
}) => (
  <CustomPicker
    title="Date"
    close={close}
    defaultTab={source ? 1 : 0}
    optionsContainer={<OptionsContainer dateTime={dateTime} value={value} onChange={onChange} close={close} />}
    variableContainer={
      onSourceChange && screenConfig && allLocalVariables ? (
        <Variable
          valueType={dateTime ? ValueType.dateTime : ValueType.date}
          value={source}
          onChange={onSourceChange}
          onRemove={() => onSourceChange()}
          close={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      ) : undefined
    }
  />
)
