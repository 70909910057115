import { Box, rgbToHex } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { ColorPicker, CommonPicker, Icon, IPickerItem, Name, OneColorSvg, TwoColorsSvg } from 'components'
import { ProjectContext } from 'context'
import {
  RightSidebarItem,
  SettingField,
  SettingNumberField,
  SettingTextAlignment,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import { getBrandingColorType, TextAlign } from 'utils'
import { Component, IText, ScreenshotsConfig } from '../../utils'
import { fontSizes, fontStylesNames } from './fontsSettings'

interface Props {
  activeElement: IText
  updateActiveElement: (activeElement: IText) => void
  close: () => void
  root: SVGElement | null
  config: ScreenshotsConfig
}

export const TextSettings: React.FC<Props> = ({ activeElement, updateActiveElement, close, root, config }) => {
  const {
    value,
    color,
    colorName,
    fontFamily,
    fontWeight,
    fontStyle,
    fontSize,
    textAlign,
    verticalAlign,
    lineHeight,
    letterSpacing,
  } = activeElement

  const fontStyleOption = fontWeight + fontStyle

  const [googleFonts, setGoogleFonts] = useState<any[]>([])
  const [fontFamilies, setFontFamilies] = useState<IPickerItem[]>([])
  const [fontStyles, setFontStyles] = useState<IPickerItem[]>([])
  const {
    config: {
      branding: { colorStyles },
    },
    theme,
  } = useContext(ProjectContext)
  const currentColor = colorName ? colorStyles[getBrandingColorType(colorName)][theme] : color

  useEffect(() => {
    fetch('/jsons/googleFonts.json')
      .then(response => response.json())
      .then(res => {
        setGoogleFonts(res.data)
        setFontFamilies(res.data.map((el: any) => ({ value: el.family, label: el.family })))
      })
  }, [])

  const onTextChange = (value: string) => updateActiveElement({ ...activeElement, value })

  const onFontFamilyChange = (fontFamily: string) =>
    updateActiveElement({ ...activeElement, fontFamily, fontWeight: '400', fontStyle: 'normal' })

  useEffect(() => {
    if (googleFonts.length) {
      const googleFont = googleFonts.find(el => el.family === fontFamily)
      const fontStyles = googleFont?.variants
      if (fontStyles) {
        setFontStyles(
          fontStyles.map((el: any) => {
            const fontStyleOption =
              el === 'regular'
                ? '400normal'
                : !el.includes('italic')
                ? el + 'normal'
                : el === 'italic'
                ? '400italic'
                : el
            return {
              value: fontStyleOption,
              // @ts-ignore
              label: fontStylesNames[fontStyleOption],
            }
          }) || []
        )
      }
    }
  }, [fontFamily, JSON.stringify(googleFonts)])

  const onFontStyleChange = (value: string) =>
    updateActiveElement({ ...activeElement, fontWeight: value.slice(0, 3), fontStyle: value.slice(3) })

  useEffect(() => {
    if (root && googleFonts.length) {
      const currentFontsNames = (config.elements || [])
        .filter(el => el.type === Component.TEXT)
        .map((el: any) => el.fontFamily + el.fontWeight + el.fontStyle)
        .filter((x: any, i: number, a: any[]) => a.indexOf(x) === i)

      const allFontsStyles = Array.from(root.querySelectorAll('style'))
      const allFontsStylesNames = allFontsStyles.map(el => el.id)

      const defaultFont = 'Roboto700normal'
      allFontsStylesNames.forEach(el => {
        if (!currentFontsNames.includes(el) && el !== defaultFont) {
          const style = root.querySelector(`style[id="${el}"]`)
          style?.parentNode?.removeChild(style)
        }
      })

      const googleFont = googleFonts.find(el => el.family === fontFamily)
      const fontName = fontFamily + fontStyleOption
      if (!allFontsStylesNames.includes(fontName)) {
        const xhr = new XMLHttpRequest()
        xhr.onload = () => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const style = document.createElement('style')
            style.id = fontName
            style.textContent = `@font-face { font-family: ${fontFamily}; font-style: ${fontStyle}; font-weight: ${fontWeight}; src: url(${reader.result}) format('truetype'); }`
            root.appendChild(style)
          }
          reader.readAsDataURL(xhr.response)
        }
        xhr.open(
          'GET',
          googleFont.files[
            fontStyleOption === '400normal'
              ? 'regular'
              : fontStyleOption === '400italic'
              ? 'italic'
              : fontStyle === 'normal'
              ? fontWeight
              : fontStyleOption
          ].replace('http://', 'https://')
        )
        xhr.responseType = 'blob'
        xhr.send()
      }
    }
  }, [fontFamily, fontWeight, fontStyle, JSON.stringify(googleFonts)])

  const onFontSizeChange = (fontSize: string) => updateActiveElement({ ...activeElement, fontSize })

  const onLineHeightChange = (lineHeight: string) => updateActiveElement({ ...activeElement, lineHeight })

  const onLetterSpacingChange = (letterSpacing: string) => updateActiveElement({ ...activeElement, letterSpacing })

  const onTextAlignChange = (textAlign: TextAlign) => updateActiveElement({ ...activeElement, textAlign })

  const onVerticalAlignChange = (verticalAlign: TextAlign) => updateActiveElement({ ...activeElement, verticalAlign })

  const onColorChange = (color: string) => updateActiveElement({ ...activeElement, color, colorName: '' })

  const onColorNameChange = (colorName: string) => updateActiveElement({ ...activeElement, colorName, color: '' })

  const sectionName = 'Text'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon onClose={close}>
      <SubWrapper childrenFor={sectionName}>
        <SettingTextField label="Text" value={value} onChange={onTextChange} />
        <SettingField
          subLabel="Color"
          value={colorName || (color.startsWith('rgb') ? rgbToHex(color) : color)}
          leftIcon={
            colorName ? (
              <TwoColorsSvg
                left={colorStyles[getBrandingColorType(colorName)].light}
                right={colorStyles[getBrandingColorType(colorName)].dark}
              />
            ) : (
              <OneColorSvg color={currentColor} />
            )
          }
          optionsContainer={
            <ColorPicker
              close={() => {}}
              colorName={colorName}
              onColorNameChange={onColorNameChange}
              color={currentColor}
              onColorChange={onColorChange}
              onlySolid
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Font" childrenFor={sectionName}>
        <SettingField
          value={fontFamily}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_TEXT_STYLE} />}
          optionsContainer={
            <CommonPicker
              title="Font families"
              value={fontFamily}
              onChange={onFontFamilyChange}
              values={fontFamilies}
              close={() => {}}
            />
          }
        />
        <Box display="flex" gap="14px">
          <Box width="100%">
            <SettingField
              // @ts-ignore
              value={fontStylesNames[fontStyleOption]}
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_LINE_HEIGHT} />}
              optionsContainer={
                <CommonPicker
                  title="Font styles"
                  value={fontStyleOption}
                  onChange={onFontStyleChange}
                  values={fontStyles}
                  close={() => {}}
                />
              }
            />
          </Box>
          <Box width="100%">
            <SettingField
              value={fontSize}
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_TEXT_STYLE_INVERTED} />}
              optionsContainer={
                <CommonPicker
                  title="Font sizes"
                  value={fontSize}
                  onChange={onFontSizeChange}
                  values={fontSizes}
                  close={() => {}}
                />
              }
            />
          </Box>
        </Box>
        <Box display="flex" gap="14px">
          <Box width="100%">
            <SettingNumberField
              value={lineHeight}
              onChange={onLineHeightChange}
              units="%"
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_LINE_HEIGHT} />}
              onlyNumber
            />
          </Box>
          <Box width="100%">
            <SettingNumberField
              value={letterSpacing}
              onChange={onLetterSpacingChange}
              units="px"
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_LETTER_SPACING} />}
              onlyNumber
            />
          </Box>
        </Box>
        <SettingTextAlignment
          subLabel="Alignment"
          value={textAlign}
          onChange={onTextAlignChange}
          verticalValue={verticalAlign}
          onVerticalChange={onVerticalAlignChange}
        />
      </SubWrapper>
    </RightSidebarItem>
  )
}
