import { GetVariable, setInputParameters, SetLocalVariables } from 'hooks'
import {
  base64Roboto,
  base64SF,
  BrandingThemeType,
  Config,
  convertSvgStringToImage,
  createAlertScreen,
  createScreen,
  createTabBar,
  createTopBar,
  getShadow,
  runActions,
  Screen,
  screenHeight,
  screenWidth,
  setComponents,
  setTabs,
} from 'utils'

export const getBackScreenConfig = (screenName: string, screens: Screen[]) => {
  if (
    screenName === 'Push Notifications System Alert' ||
    screenName === 'Camera System Alert' ||
    screenName === 'Gallery System Alert'
  ) {
    return screens.find(el => el.screenName === 'Push Notifications Permissions')
  } else if (screenName === 'Idfa Tracking SystemAlert') {
    return screens.find(el => el.screenName === 'Idfa Tracking Permissions')
  }
}

export const getImageByConfigs = async (
  screenConfig: Screen,
  config: Config,
  getVariable: GetVariable,
  setLocalVariables: SetLocalVariables,
  theme: BrandingThemeType
) => {
  const {
    screenName,
    components = [],
    topBar,
    showTopBar,
    showBackButton,
    screenTitle,
    inputParameters,
    localVariables,
    onScreenVisitActions,
  } = screenConfig
  const {
    branding: {
      typography: { iosDefaultFontFamily: fontFamily, fontStyles = [] },
    },
    tabBars = [],
    screens = [],
  } = config
  const tabBar = tabBars.find(el => el.tabs?.find(el => el.screen === screenName))
  const backScreenConfig = getBackScreenConfig(screenName, screens)

  const shadow = getShadow()
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  shadow.appendChild(svg)
  svg.setAttribute('viewBox', `0 0 ${screenWidth} ${screenHeight}`)
  svg.setAttribute('width', String(screenWidth))
  svg.setAttribute('height', String(screenHeight))
  const screenWrapper = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  svg.appendChild(screenWrapper)
  screenWrapper.setAttribute('width', String(screenWidth))
  screenWrapper.setAttribute('height', String(screenHeight))

  try {
    const getVariableValue = getVariable(screenConfig)
    if (inputParameters) {
      await setInputParameters(inputParameters, getVariableValue)
    }
    if (localVariables) {
      await setLocalVariables(getVariableValue, localVariables)
    }
    if (onScreenVisitActions) {
      await runActions(getVariableValue, onScreenVisitActions)
    }
    const screen = await createScreen('', screenConfig, screenWrapper, getVariableValue)
    const showTopBarValue = await getVariableValue({ ...showTopBar, booleanConstant: showTopBar?.constant })
    if (showTopBarValue) {
      await createTopBar(
        '',
        '',
        screen,
        fontFamily,
        fontStyles,
        theme,
        1,
        screenConfig,
        getVariableValue,
        getVariable,
        setLocalVariables,
        topBar,
        showBackButton,
        screenTitle
      )
    }
    if (components.length) {
      await setComponents(
        components,
        screen,
        fontFamily,
        fontStyles,
        theme,
        1,
        true,
        screenConfig,
        getVariableValue,
        getVariable,
        setLocalVariables
      )
    }
    if (tabBar) {
      const screenTabBar = await createTabBar('', '', tabBar, screen, getVariableValue)
      await setTabs(screenName, tabBar, screenTabBar, fontFamily, getVariableValue)
    }
    if (backScreenConfig) {
      createAlertScreen(
        screen,
        screenWrapper,
        await getImageByConfigs(backScreenConfig, config, getVariable, setLocalVariables, theme)
      )
    }

    const style = document.createElement('style')
    svg.appendChild(style)
    style.innerHTML = '*::-webkit-scrollbar{display: none;} *[hidden]{display: none !important;}'
    svg.innerHTML = (fontFamily === 'Roboto' ? base64Roboto : base64SF) + svg.innerHTML

    const serializer = new XMLSerializer()
    const source = serializer.serializeToString(svg)
    const url = convertSvgStringToImage(source)
    return url
  } catch {
    return ''
  } finally {
    shadow.parentNode?.removeChild(shadow)
  }
}
