import React from 'react'

import * as styled from './ReadOnlyMode.styled'

import { Button, ButtonColor, DropDown, Icon, Name } from 'components'
import { landingUrl } from 'utils'

export const ReadOnlyMode: React.FC = () => (
  <styled.ReadOnlyMode>
    <div>Verification required</div>
    <div>Join the Preview Program to gain access</div>
    <div>
      <Button
        color={ButtonColor.BLACK}
        to="https://discord.gg/ghRPaYRnVp"
        leftIcon={<Icon name={Name.ADDITIONAL_DISCORD} />}
      >
        Community
      </Button>
      <Button color={ButtonColor.SECONDARY} to={`${landingUrl}/request-a-demo`}>
        Contact Us
      </Button>
    </div>
    <div>
      <DropDown
        options={[
          { label: 'Community', to: 'https://discord.gg/ghRPaYRnVp' },
          { label: 'Contact Us', to: `${landingUrl}/request-a-demo` },
        ]}
        top="25px"
        width="110px"
        labelElement={<Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />}
      />
    </div>
  </styled.ReadOnlyMode>
)
