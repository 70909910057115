import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './RefPicker.styled'

import {
  Button,
  ButtonColor,
  CustomPicker,
  isFiltersNotValid,
  NoContent,
  PickerOverlay,
  PickerWrapper,
  Text,
  Type,
} from 'components'
import { ReferenceItem } from 'components/FieldTypes/components/RefField/ReferenceItems'
import { DeviceSettingsContext, ProgressContext, ProjectContext } from 'context'
import { collectionPrefix, queryToString, useVariable } from 'hooks'
import { CMSCollectionRecord, CMSService, ResourceTypes } from 'services/cms'
import {
  Collection,
  Screen,
  TableQuery,
  TableQueryFilterOperator,
  ValueType,
  VariableSource,
  VariableSourceType,
} from 'utils'
import { CustomSearch } from './components'

interface Props {
  close: () => void
  currentRef: Collection
  values: CMSCollectionRecord[] | string[]
  onChange: (val: CMSCollectionRecord | CMSCollectionRecord[] | string | string[] | null) => void
  resourceType?: ResourceTypes
  multiple?: boolean
  title: string
}

export const RefPicker: React.FC<Props> = ({ close, currentRef, values, onChange, resourceType, multiple, title }) => {
  const themeContext = useContext(ThemeContext)
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: { screens = [] },
  } = useContext(ProjectContext)
  const { device } = useContext(DeviceSettingsContext)
  const { getVariable } = useVariable(device)
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const [loading, setLoading] = useState(true)
  const [records, setRecords] = useState<CMSCollectionRecord[]>([])
  const [selectedValues, setSelectedValues] = useState(values)
  const [currentVariable, setCurrentVariable] = useState<VariableSource>({
    type: VariableSourceType.collection,
    query: {
      filters:
        resourceType && resourceType !== ValueType.file
          ? [
              {
                field: 'resourceType',
                operator: TableQueryFilterOperator.equal,
                value: { stringConstant: resourceType },
                default: true,
              },
            ]
          : undefined,
    },
  })

  const getRecords = async (abortController: AbortController, query?: TableQuery) => {
    if (!isFiltersNotValid(query?.filters)) {
      setLoading(true)
      CMSService.getRecords(
        id,
        currentRef.name,
        await queryToString(getVariable(screenConfig), query),
        undefined,
        undefined,
        undefined,
        abortController
      )
        .then(res => setRecords(res.data))
        .catch(err => !abortController.signal.aborted && toast(err, true))
        .finally(() => !abortController.signal.aborted && setLoading(false))
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    getRecords(abortController, currentVariable.query)
    return () => {
      abortController.abort()
    }
  }, [JSON.stringify(currentVariable.query)])

  useEffect(() => {
    setSelectedValues(values)
  }, [JSON.stringify(values)])

  const onClick = (val: CMSCollectionRecord | string, active: boolean) =>
    // @ts-ignore
    setSelectedValues(selectedValues =>
      active
        ? // @ts-ignore
          selectedValues.filter(el =>
            resourceType ? (el as CMSCollectionRecord).id !== (val as CMSCollectionRecord).id : el !== val
          )
        : [...selectedValues, val]
    )

  const onMultipleSelect = () => {
    onChange(selectedValues.length ? selectedValues : null)
    close()
  }

  const onSingleSelect = (val: CMSCollectionRecord | string) => {
    onChange(val)
    close()
  }

  return (
    <PickerWrapper>
      <PickerOverlay onClick={close} />
      <Box width="700px" zIndex="1">
        <CustomPicker
          title={`Add existing ${title}`}
          close={close}
          optionsContainer={
            <>
              <CustomSearch
                currentVariable={currentVariable}
                setCurrentVariable={setCurrentVariable}
                properties={currentRef.properties || []}
                wide
              />
              <styled.Items referenceMode={!resourceType}>
                {loading ? (
                  <Text type={Type.BODY} fontSize="14px" textAlign="center" width="100%">
                    Loading...
                  </Text>
                ) : !records.length ? (
                  <NoContent
                    text={`Your ${resourceType ? 'folder' : 'collection'} is empty. Get started by adding a new ${
                      resourceType ? 'asset' : 'record'
                    }.`}
                  />
                ) : (
                  records.map(el => {
                    const value = resourceType ? el : `${collectionPrefix}${currentRef.name}/${el.id}`
                    // @ts-ignore
                    const active = !!selectedValues.find(el =>
                      resourceType
                        ? (el as CMSCollectionRecord).id === (value as CMSCollectionRecord).id
                        : el.includes(value)
                    )
                    return (
                      <ReferenceItem
                        key={el.id}
                        active={active}
                        value={value}
                        onClick={() => (multiple ? onClick(value, active) : onSingleSelect(value))}
                        resourceType={resourceType}
                        record={el}
                      />
                    )
                  })
                )}
              </styled.Items>
              {multiple && (
                <Box
                  display="flex"
                  gap="16px"
                  justifyContent="flex-end"
                  p="10px 16px 12px"
                  borderTop={`1px solid ${themeContext.colors.darkBlue_100}`}
                >
                  <Button color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button onClick={onMultipleSelect}>Select {title}</Button>
                </Box>
              )}
            </>
          }
        />
      </Box>
    </PickerWrapper>
  )
}
