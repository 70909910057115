import React, { useState } from 'react'

import { LocalVariable, Screen, ValueType, VariableSource } from 'utils'
import { CustomPicker } from '../CustomPicker'
import { Variable } from '../common'
import { Style, SwitchColor } from './components'

interface Props {
  close: () => void
  colorName?: string
  onColorNameChange: (val: string) => void
  color: string
  onColorChange: (val: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  onlySolid?: boolean
  withoutDelete?: boolean
}

export const ColorPicker: React.FC<Props> = ({
  close,
  colorName,
  onColorNameChange,
  color,
  onColorChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  onlySolid,
  withoutDelete,
}) => {
  const [search, setSearch] = useState('')

  return (
    <CustomPicker
      title="Style"
      close={close}
      setSearch={setSearch}
      defaultTab={colorName ? 0 : source ? 2 : 1}
      optionsContainer={<Style value={colorName} onChange={onColorNameChange} close={close} search={search} />}
      switchColorContainer={<SwitchColor value={color} onChange={onColorChange} onlySolid={onlySolid} />}
      variableContainer={
        onSourceChange && screenConfig && allLocalVariables ? (
          <Variable
            valueType={ValueType.color}
            value={source}
            onChange={onSourceChange}
            onRemove={withoutDelete ? undefined : () => onSourceChange()}
            close={() => {}}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : undefined
      }
    />
  )
}
