import React, { useContext, useMemo } from 'react'

import { BooleanInput, BrandingColorInput, CommonPicker, GradientInput, Icon, ImageInput, Name } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Module, Resource, Screen, ScreenSize } from 'utils'
import { Actions, Id, InputParameters, LocalVariables } from '../common'

const sizeValues = Object.values(ScreenSize).map(el => ({ value: el, label: el }))

interface Props {
  onScreenPropertiesChange: (props: { propName: keyof Screen; value: any; resources?: Resource[] }[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ScreenSetting: React.FC<Props> = ({ onScreenPropertiesChange, screenConfig, allLocalVariables }) => {
  const { toast } = useContext(ProgressContext)
  const { module, screenName, size, backgroundColor, backgroundGradient, backgroundPattern, showTopBar, popUp } =
    screenConfig
  const {
    config: { tabBars = [], screens = [] },
  } = useContext(ProjectContext)
  const screensNames = useMemo(
    () => screens?.map(el => el.screenName).filter(el => !!el && el !== screenName),
    [screens]
  )

  const onScreenNameChange = (value: string) => {
    if (!value) {
      toast('Screen name can not be empty!')
    } else if (screensNames.includes(value)) {
      toast(`Screen with name "${value}" already exists!`)
    } else {
      const tab = tabBars
        .find(el => el.tabs?.find(el => el.screen === screenName))
        ?.tabs?.find(el => el.screen === screenName)
      if (tab) {
        tab.screen = value
      }
      onScreenPropertiesChange([{ propName: 'screenName', value }])
    }
  }

  const sectionName = 'Screen'

  return (
    <RightSidebarItem title={sectionName} hasCloseIcon>
      <SubWrapper childrenFor={sectionName}>
        <Id value={screenName} />
        {module === Module.others && (
          <SettingTextField label="Screen name" value={screenName} onChange={onScreenNameChange} />
        )}
        <SettingField
          label="Screen size"
          value={size}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Sizes"
              value={size}
              onChange={value => onScreenPropertiesChange([{ propName: 'size', value }])}
              values={sizeValues}
              close={() => {}}
            />
          }
        />
        <BrandingColorInput
          label="Background"
          subLabel="Fill"
          value={backgroundColor?.constant}
          onChange={value => onScreenPropertiesChange([{ propName: 'backgroundColor', value: { constant: value } }])}
          source={backgroundColor?.source}
          onSourceChange={value =>
            onScreenPropertiesChange([{ propName: 'backgroundColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <GradientInput
          subLabel="Gradient"
          value={backgroundGradient}
          onChange={value => onScreenPropertiesChange([{ propName: 'backgroundGradient', value }])}
        />
        <ImageInput
          subLabel="Image"
          value={backgroundPattern?.resourceId}
          onChange={(value, resources) =>
            onScreenPropertiesChange([{ propName: 'backgroundPattern', value: { resourceId: value }, resources }])
          }
        />
      </SubWrapper>
      <SubWrapper title="Options" childrenFor={sectionName} canHide>
        <BooleanInput
          subLabel="Show top bar"
          value={showTopBar?.constant}
          onChange={value => onScreenPropertiesChange([{ propName: 'showTopBar', value: { constant: value } }])}
          source={showTopBar?.source}
          onSourceChange={value => onScreenPropertiesChange([{ propName: 'showTopBar', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BooleanInput
          subLabel="PopUp"
          value={popUp}
          onChange={value => onScreenPropertiesChange([{ propName: 'popUp', value }])}
        />
      </SubWrapper>
      <InputParameters
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'inputParameters', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <LocalVariables
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'localVariables', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <Actions
        childrenFor={sectionName}
        onChange={value => onScreenPropertiesChange([{ propName: 'onScreenVisitActions', value }])}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
