import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Button } from 'components'
import { SettingField } from 'partials/RightSidebar/components'

interface Props {
  dateTime?: boolean
  value?: string
  onChange: (val: string) => void
  close: () => void
}

const buttonPadding = { padding: '9.5px' }

export const OptionsContainer: React.FC<Props> = ({ dateTime, value, onChange, close }) => {
  const [date, setDate] = useState(value)

  useEffect(() => {
    setDate(value)
  }, [value])

  const onConfirm = () => {
    if (date) {
      onChange(date)
      close()
    }
  }

  const confirmDisabled = !date

  return (
    <Box maxHeight="500px" overflow="auto">
      <Box p="0 10px">
        <SettingField subLabel="Date" dateTime={dateTime} value={date} onDateChange={setDate} small insidePicker />
      </Box>
      <Box padding="15px 10px" display="flex" justifyContent="flex-end" width="100%">
        <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
          Confirm
        </Button>
      </Box>
    </Box>
  )
}
