import { GetVariableValue } from 'hooks'
import { BooleanVariable, ListStyle, NumberVariable, VariableSourceType } from 'utils'
import {
  listEmptyName,
  listItemName,
  listItemNameCopy,
  listSectionHeaderName,
  listSectionHeaderNameCopy,
} from './index'

const currentEmptyMode: { [key: string]: boolean } = {}

const getListItems = (list: HTMLElement, reversed?: boolean) => {
  const listItems = Array.from(
    list.querySelectorAll(
      `:scope > [id*="${listItemName}"], :scope > [id*="${listItemNameCopy}"], :scope > [id*="${listSectionHeaderName}"], :scope > [id*="${listSectionHeaderNameCopy}"]`
    )
  ) as HTMLElement[]
  return reversed ? listItems.reverse() : listItems
}

export const listHandler = (list: HTMLElement, listStyle?: ListStyle) =>
  setEmptyMode(list, listStyle, currentEmptyMode[list.id])

export const setEmptyMode = (list: HTMLElement, listStyle?: ListStyle, set?: boolean) => {
  const listEmpty = list.querySelector(`:scope > [id*="${listEmptyName}"]`) as HTMLElement | null
  const listItems = getListItems(list)
  if (listEmpty) {
    if (set) {
      listItems.forEach(el => (el.hidden = true))
      listEmpty.removeAttribute('hidden')
      currentEmptyMode[list.id] = true
    } else {
      listItems.forEach(el => el.removeAttribute('hidden'))
      listEmpty.hidden = true
      delete currentEmptyMode[list.id]
    }
  }
  if (listStyle === ListStyle.gallery) {
    list.style.scrollSnapType = 'x mandatory'
    listItems.forEach(el => (el.style.scrollSnapAlign = 'center'))
  }
}

export const listGalleryHandler = async (
  list: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: NumberVariable,
  reversed?: BooleanVariable,
  name?: string
) => {
  const valueValue = await getVariableValue({ ...value, numberConstant: value?.constant })
  const reversedValue = await getVariableValue({ ...reversed, booleanConstant: reversed?.constant })
  const listItems = getListItems(list, reversedValue)
  const currentValue = valueValue || (reversedValue ? listItems.length - 1 : 0)
  const listItem = listItems[currentValue]
  if (listItem) {
    const { offsetLeft, offsetTop } = listItem
    list.scrollTo(offsetLeft, offsetTop)
  }
  const setValue = async () => {
    const { x, y } = list.getBoundingClientRect()
    let value = 0
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i]
      const { x: iX, y: iY } = listItem.getBoundingClientRect()
      if (x <= iX && y <= iY) {
        value = i
        break
      }
    }
    await getVariableValue(
      { source: { type: VariableSourceType.component, componentName: name, fieldName: 'value' } },
      { value }
    )
  }
  await setValue()
}
