import React from 'react'

import { BooleanInput, BrandingColorInput } from 'components'
import { RightSidebarItem, SettingNumberField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, ScreenComponent } from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ProgressIndicatorSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { componentType, name, maximumValue, value, primaryColor, secondaryColor, duration, loop } = component

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <BrandingColorInput
          subLabel="Leading track"
          value={primaryColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'primaryColor', value: { constant: value } }])}
          source={primaryColor?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'primaryColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BrandingColorInput
          subLabel="Trailing track"
          value={secondaryColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'secondaryColor', value: { constant: value } }])}
          source={secondaryColor?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'secondaryColor', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Segments"
          value={maximumValue?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'maximumValue', value: { constant: value } }])}
          source={maximumValue?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'maximumValue', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Value"
          value={value?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'value', value: { constant: value } }])}
          source={value?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'value', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingNumberField
          subLabel="Duration (sec)"
          value={duration?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'duration', value: { constant: value } }])}
          source={duration?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'duration', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BooleanInput
          subLabel="Loop"
          value={loop?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'loop', value: { constant: value } }])}
          source={loop?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'loop', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
