import { GetVariableValue } from 'hooks'
import { Action, ActionType } from 'utils'

export const runActions = async (getVariableValue: GetVariableValue, actions: Action[]) => {
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i]
    const { actionType, variable, valueTarget } = action
    if (actionType === ActionType.setValue && valueTarget && variable) {
      const value = await getVariableValue(variable)
      await getVariableValue({ source: valueTarget }, { value })
    }
  }
}
