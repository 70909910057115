import { Dispatch, SetStateAction } from 'react'

import { GetVariable, GetVariableValue, SetLocalVariables } from 'hooks'
import {
  BrandingFontStyle,
  BrandingThemeType,
  ComponentType,
  Screen,
  ScreenComponent,
  carouselHandler,
  createElement,
  getComponentTypeById,
  listHandler,
  listItemName,
  listItemNameCopy,
  listSectionHeaderName,
  listSectionHeaderNameCopy,
  overlayHandler,
  progressIndicatorHandler,
  showMoreButtonHandler,
} from 'utils'

export const setComponents = async (
  components: ScreenComponent[],
  parent: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  theme: BrandingThemeType,
  compression: number,
  firstChildOfScreen: boolean,
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  setLocalVariables: SetLocalVariables,
  setScroll?: (func: () => void) => void,
  onClickComponent?: (componentId: string, subComponentId: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>,
  setMovableComponent?: (config: ScreenComponent) => void,
  innerModeComponentId?: string
) => {
  const setSubComponents = async (element: HTMLElement, component: ScreenComponent) => {
    const {
      id,
      name,
      componentType,
      subComponents = [],
      listId,
      indexInList,
      numberOfLines,
      showMoreTextButton,
      value,
      duration,
      loop,
      listStyle,
    } = component
    const isListItem = name === listItemName || name === listItemNameCopy
    const isListSectionHeader = name === listSectionHeaderName || name === listSectionHeaderNameCopy
    const isOverlay = componentType === ComponentType.overlay
    const isText = componentType === ComponentType.text
    const isCarousel = componentType === ComponentType.carousel
    const isProgressIndicator = componentType === ComponentType.progressIndicator
    const isList = componentType === ComponentType.list
    const fromVB = !!(onClickComponent && setMovableComponent && setHoveredId && setScroll)
    await setComponents(
      subComponents,
      element,
      fontFamily,
      fontStyles,
      theme,
      compression,
      false,
      screenConfig,
      isListItem || isListSectionHeader ? getVariable(screenConfig, listId, indexInList) : getVariableValue,
      getVariable,
      setLocalVariables,
      setScroll,
      onClickComponent,
      setHoveredId,
      setMovableComponent,
      isCarousel || isList ? id : innerModeComponentId
    )
    if (isOverlay) {
      overlayHandler(element, compression)
    } else if (isText) {
      await showMoreButtonHandler(element, getVariableValue, numberOfLines, showMoreTextButton)
    } else if (isCarousel) {
      await carouselHandler(element, getVariableValue, value)
    } else if (isProgressIndicator) {
      await progressIndicatorHandler(fromVB, element, getVariableValue, value, duration, loop)
    } else if (isList) {
      listHandler(element, listStyle)
    }
  }
  for (const component of components) {
    const { name, listId, indexInList } = component
    const isListItem = name === listItemName || name === listItemNameCopy
    const isListSectionHeader = name === listSectionHeaderName || name === listSectionHeaderNameCopy
    const onlyChild =
      firstChildOfScreen ||
      (!isListItem &&
        !isListSectionHeader &&
        (getComponentTypeById(parent.id) === ComponentType.carousel || components.length === 1))
    await createElement(
      setSubComponents,
      component,
      parent,
      fontFamily,
      fontStyles,
      theme,
      compression,
      firstChildOfScreen,
      onlyChild,
      screenConfig,
      isListItem || isListSectionHeader ? getVariable(screenConfig, listId, indexInList) : getVariableValue,
      getVariable,
      setLocalVariables,
      setScroll,
      onClickComponent,
      setHoveredId,
      setMovableComponent,
      innerModeComponentId
    )
  }
}
