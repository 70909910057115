import {
  AdmonitionDirectiveDescriptor,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ChangeCodeMirrorLanguage,
  CodeToggle,
  ConditionalContents,
  CreateLink,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  MDXEditor,
  Separator,
  codeBlockPlugin,
  codeMirrorPlugin,
  diffSourcePlugin,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor'
import { memo, useContext, useEffect, useState } from 'react'

import '@mdxeditor/editor/style.css'
import * as styled from './RichText.styled'

import { ProgressContext, ProjectContext } from 'context'
import { collectionPrefix } from 'hooks'
import { CMSCollectionRecord, CMSService, ResourceTypes, assetsId } from 'services/cms'
import { ValueType } from 'utils'
import { FieldProps } from '../../types'
import { RefField } from '../RefField'

const getAssetIdFromUrl = (url: string) => url.split('/').slice(-1)[0] as string
const firebaseUrlRegExp = new RegExp(/collection:\/\/assets\/[a-zA-Z0-9-]+/g)
// eslint-disable-next-line
const imageUrlRegExp = new RegExp(/\!\[\]\((?:...+)\)/g)
const resources = [
  ValueType.image,
  // ValueType.video
  // ValueType.audio
  // ValueType.file
] as ResourceTypes[]

interface Props extends FieldProps {
  value: string | null
  onChange: (val: string | null) => void
}

export const RichText = memo((props: Props) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
  } = useContext(ProjectContext)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [value, setValue] = useState(props.value)
  const [assets, setAssets] = useState<CMSCollectionRecord[]>([])

  useEffect(() => {
    if (value !== props.value) {
      let newValue = value
      const matches = value?.match(imageUrlRegExp)
      matches?.forEach(match => {
        const assetId = assets.find(asset => match.includes(asset.url))?.id
        if (assetId) {
          newValue = newValue ? newValue.replace(match, `${collectionPrefix}${assetsId}/${assetId}`) : null
        }
      })
      props.onChange(newValue || null)
    }

    let newValue = value
    const matches = value?.match(firebaseUrlRegExp)
    if (matches) {
      const assetsIds: string[] = []
      matches.forEach(match => {
        const assetId = getAssetIdFromUrl(match)
        if (!assets.find(asset => asset.id === assetId)) {
          assetsIds.push(getAssetIdFromUrl(match))
        }
      })
      if (assetsIds.length) {
        CMSService.getRecords(id, assetsId, undefined, undefined, assetsIds)
          .then(res => {
            const newAssets = [...assets, ...res.data]
            matches.forEach(match => {
              const assetId = getAssetIdFromUrl(match)
              const url = newAssets.find(asset => asset.id === assetId)?.url
              if (url) {
                newValue = newValue ? newValue.replace(match, `![](${url})`) : null
              }
            })
            setValue(newValue)
            setLastUpdate(+new Date())
            setAssets(newAssets)
          })
          .catch(err => toast(err, true))
      }
    }
  }, [value])

  const addAsset = (val: CMSCollectionRecord) =>
    setValue(value => `${value} \n${collectionPrefix}${assetsId}/${val.id}`)

  return (
    <styled.RichTextWrapper>
      <MDXEditor
        key={lastUpdate}
        markdown={value || ''}
        onChange={setValue}
        plugins={[
          toolbarPlugin({
            toolbarContents: () => (
              <ConditionalContents
                options={[
                  {
                    when: editor => editor?.editorType === 'codeblock',
                    contents: () => <ChangeCodeMirrorLanguage />,
                  },
                  {
                    fallback: () => (
                      <>
                        <BlockTypeSelect />
                        <Separator />
                        <BoldItalicUnderlineToggles />
                        <Separator />
                        <CodeToggle />
                        <Separator />
                        <ListsToggle />
                        <Separator />
                        <CreateLink />
                        <Separator />
                        <InsertTable />
                        <InsertThematicBreak />
                        <styled.RefFieldsWrapper>
                          {resources.map(el => (
                            <RefField
                              key={el}
                              {...props}
                              resourceType={el}
                              value={null}
                              onChange={value => addAsset(value as CMSCollectionRecord)}
                            />
                          ))}
                        </styled.RefFieldsWrapper>
                      </>
                    ),
                  },
                ]}
              />
            ),
          }),
          listsPlugin(),
          quotePlugin(),
          headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
          linkPlugin(),
          linkDialogPlugin(),
          tablePlugin(),
          imagePlugin(),
          thematicBreakPlugin(),
          codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
          codeMirrorPlugin({
            codeBlockLanguages: {
              js: 'JavaScript',
              css: 'CSS',
              txt: 'text',
              tsx: 'TypeScript',
            },
          }),
          directivesPlugin({
            directiveDescriptors: [AdmonitionDirectiveDescriptor],
          }),
          diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
          markdownShortcutPlugin(),
        ]}
      />
    </styled.RichTextWrapper>
  )
})
