import { memo } from 'react'

import { FieldProps } from '../../types'

interface Props extends FieldProps {
  dateTime?: boolean
  value: string | null
  onChange: (val: string | null) => void
}

export const DateInput = memo((props: Props) => (
  <input
    type={props.dateTime ? 'datetime-local' : 'date'}
    value={props.value ? (props.dateTime ? props.value.slice(0, 16) : props.value) : ''}
    onChange={({ target: { value } }) =>
      props.onChange(
        value ? (props.dateTime ? new Date(value).toISOString() : new Date(value).toISOString().slice(0, 10)) : null
      )
    }
    placeholder={props.placeholder}
    disabled={props.disabled}
    data-1p-ignore
  />
))
