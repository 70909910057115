import styled from 'styled-components'

export const ReadOnlyMode = styled.div`
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: #dd3e00;
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: ${props => props.theme.colors.white};
    &:nth-child(1) {
      width: 224px;
      flex-shrink: 0;
      font-weight: 600;
    }
    &:nth-child(2) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
    &:nth-child(3) {
      width: 224px;
      flex-shrink: 0;
      justify-content: flex-end;
    }
    &:nth-child(4) {
      display: none;
      & svg {
        cursor: pointer;
        & circle {
          transition: 300ms ease-out;
          fill: ${props => props.theme.colors.white};
        }
        &:hover circle {
          fill: ${props => props.theme.colors.hoverWhite};
        }
      }
    }
  }
  @media (max-width: 780px) {
    gap: 8px;
    & > div {
      gap: 8px;
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        justify-content: flex-start;
        text-align: start;
        font-weight: 600;
      }
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: flex;
      }
    }
  }
`
