import { marked } from 'marked'
import { useContext } from 'react'
import { format } from 'timeago.js'

import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService, assetsId, profilesId } from 'services/cms'
import firebase from 'services/firebase'
import {
  AnyVariable,
  BrandingColorType,
  Collection,
  CollectionProperty,
  CollectionVariable,
  DateVariable,
  LocalVariable,
  LocalizedContent,
  NumberVariable,
  Screen,
  ScreenComponent,
  ScreenParameter,
  TableQuery,
  TableQueryOrder,
  ValueType,
  VariableSourceType,
  VariableTransform,
  VariableTransformTransform,
  findComponent,
  getNumberValue,
  getTextValue,
  needOrderByOperators,
  operators,
} from 'utils'

export type GetVariable = (screenConfig: Screen, listId?: string, indexInList?: number) => GetVariableValue

export type GetVariableValue = (
  variable?: AnyVariable,
  toSet?: { value: any },
  initialSet?: boolean,
  listParams?: { listId: string; listItemContextKey: string; limit?: number }
) => Promise<any>

export type SetLocalVariables = (getVariableValue: GetVariableValue, localVariables: LocalVariable[]) => Promise<void>

export interface CollectionWithRecords extends Collection {
  collectionId: string
  records: CMSCollectionRecord[]
  queryString?: string
  limit?: number
}

let variables: {
  [key: string]: any
} = {}
let firebaseDatasources: { [key: string]: CMSCollectionRecord } = {}

export const clearVariables = () => {
  variables = {}
  firebaseDatasources = {}
}

export const removeOldLocalVariable = (screenConfig: Screen, listId: string, listItemContextKey: string) => {
  const screenId = screenConfig.id
  delete variables[screenId + listId + listItemContextKey]
}

export const useVariable = (device: string) => {
  const { toast } = useContext(ProgressContext)
  const {
    project: { id },
    config: {
      data: { collections = [], globalVariables = [] },
      branding: {
        appName,
        icons: { iosIcon },
        colorStyles,
      },
      resources = [],
      tabBars = [],
    },
    language,
    theme,
  } = useContext(ProjectContext)
  const applicationName = appName.locales[language]
  const applicationIcon = resources.find(el => el.id === iosIcon)?.url

  const getVariable: GetVariable = (screenConfig, listId, indexInList) => {
    const getVariableValue: GetVariableValue = async (variable, toSet, initialSet, listParams) => {
      if (variable) {
        const getVariableValue = getVariable(screenConfig, listId, indexInList)
        const {
          source,
          textConstant,
          numberConstant,
          booleanConstant,
          calendarStyleConstant,
          colorConstant,
          urlConstant,
          imageConstant,
          videoConstant,
          dateConstant,
          dateTimeConstant,
          accessLevelConstant,
          arrayConstant,
          stringConstant,
        } = variable
        if (source !== undefined) {
          const { type, collection, fieldName, selector, query, transforms, variableName, componentName } = source
          const screenId = screenConfig.id
          switch (type) {
            case VariableSourceType.globalVariable:
              if (variableName) {
                let value = null
                switch (variableName) {
                  case 'currentUserId':
                    value = 'CAgla5YRTxbsi71r5VUaArj1d0C3'
                    break
                  case 'applicationVersion':
                    value = 'v.1.0.1 (1)'
                    break
                  case 'applicationName':
                    value = applicationName
                    break
                  case 'applicationIcon':
                    value = applicationIcon
                    break
                  case 'isSignedOut':
                    value = false
                    break
                  case 'isAnonymous':
                    value = false
                    break
                  case 'hasUserAccount':
                    value = false
                    break
                  case 'isEmailVerified':
                    value = false
                    break
                  case 'accessLevels':
                    const globalVariable = globalVariables.find(el => el.variableName === variableName)
                    if (globalVariable) {
                      value = await getVariableValue(globalVariable.variable)
                    }
                    break
                }
                return convertValue(value, getVariableValue, transforms)
              }
              return null
            case VariableSourceType.collection:
              if (collection) {
                const collectionId = await getCurrentCollectionId(collection, getVariableValue)
                const collectionData = collections.find(el => el.name === collection.name)
                if (collectionData) {
                  const firstTransform = transforms?.find(el => el.transform === VariableTransformTransform.first)
                  const { properties = [] } = collectionData
                  const property = properties.find(el => el.name === fieldName)
                  const recordId = await getVariableValue({ ...selector, stringConstant: selector?.constant })
                  if (listParams) {
                    const { listId, listItemContextKey, limit } = listParams
                    const name = screenId + listId + listItemContextKey
                    const queryString = await queryToString(getVariableValue, query, true)
                    const variable = variables[name]
                    if (
                      !variable ||
                      (typeof variable === 'object' &&
                        (variable.collectionId !== collectionId ||
                          variable.queryString !== queryString ||
                          variable.limit !== limit))
                    ) {
                      if (recordId) {
                        const firebaseDatasourceName = `${collectionId}/${recordId}`
                        const set = async (record: CMSCollectionRecord) => {
                          firebaseDatasources[firebaseDatasourceName] = record
                          if (fieldName && record[fieldName] && property) {
                            const subCollection =
                              property.type === ValueType.array &&
                              property.accept === ValueType.record &&
                              collections.find(el => property.collection && el.name === property.collection.name)
                            if (subCollection) {
                              const recordsIds = record[fieldName].map((el: string) => extractRecordRef(el).recordId)
                              if (recordsIds.length) {
                                const set = (records: CMSCollectionRecord[]) => {
                                  variables[name] = {
                                    ...subCollection,
                                    collectionId,
                                    records,
                                    queryString,
                                    limit,
                                  }
                                }
                                await getRecords(set, subCollection.name, queryString, limit, recordsIds)
                              }
                            }
                          }
                        }
                        if (!firebaseDatasources[firebaseDatasourceName]) {
                          await getRecord(set, collectionId, recordId)
                        } else {
                          await set(firebaseDatasources[firebaseDatasourceName])
                        }
                      } else {
                        const set = (records: CMSCollectionRecord[]) => {
                          variables[name] = {
                            ...collectionData,
                            collectionId,
                            records,
                            queryString,
                            limit,
                          }
                        }
                        await getRecords(set, collectionId, queryString, limit)
                      }
                    }
                    return variables[name]
                  } else if (recordId && fieldName && property) {
                    const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                    return convertValue(record[fieldName], getVariableValue, transforms, property)
                  } else if (recordId && transforms) {
                    const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                    return convertValue(record, getVariableValue, transforms, undefined, true)
                  } else if (recordId && initialSet) {
                    const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                    return {
                      ...collectionData,
                      collectionId,
                      records: [record],
                    }
                  } else if (recordId) {
                    if (collectionId === assetsId) {
                      const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                      return record
                    } else {
                      return `${collectionPrefix}${collectionId}/${recordId}`
                    }
                  } else if (firstTransform) {
                    const queryString = await queryToString(getVariableValue, query, true)
                    let variable: CollectionWithRecords | undefined
                    const set = (records: CMSCollectionRecord[]) => {
                      variable = {
                        ...collectionData,
                        collectionId,
                        records,
                      }
                    }
                    await getRecords(set, collectionId, queryString, 1)
                    if (initialSet) {
                      return variable
                    } else {
                      const recordId = variable?.records[0]?.id
                      return `${collectionPrefix}${collectionId}/${recordId}`
                    }
                  } else if (transforms) {
                    return convertValue({}, getVariableValue, transforms, undefined, true)
                  } else if (collectionId === assetsId && initialSet) {
                    return {
                      ...collectionData,
                      collectionId,
                      records: [{}],
                    }
                  }
                }
              }
              return null
            case VariableSourceType.localVariable:
              if (variableName) {
                const innerListItemValue = variables[screenId + listId + variableName + indexInList]
                const listItemsValue = variables[screenId + listId + variableName]
                if (toSet !== undefined) {
                  let name
                  if (initialSet) {
                    name =
                      listId && indexInList !== undefined
                        ? screenId + listId + variableName + indexInList
                        : listId
                        ? screenId + listId + variableName
                        : screenId + variableName
                  } else {
                    name =
                      innerListItemValue !== undefined
                        ? screenId + listId + variableName + indexInList
                        : listItemsValue !== undefined
                        ? screenId + listId + variableName
                        : screenId + variableName
                  }
                  if (JSON.stringify(variables[name]) !== JSON.stringify(toSet.value)) {
                    variables[name] = toSet.value
                  }
                  return variables[name]
                }
                const name =
                  innerListItemValue !== undefined
                    ? screenId + listId + variableName + indexInList
                    : listItemsValue !== undefined
                    ? screenId + listId + variableName
                    : screenId + variableName
                const variable = variables[name]
                if (
                  variable &&
                  typeof variable === 'object' &&
                  variable.name &&
                  variable.collectionId &&
                  variable.records
                ) {
                  const { name, collectionId: cId, records } = variable
                  const collectionId = cId || name
                  const record = innerListItemValue !== undefined ? records[0] || {} : records[indexInList || 0] || {}
                  const recordId = record.id as string | undefined
                  if (recordId) {
                    const firebaseDatasourceName = `${collectionId}/${recordId}`
                    firebaseDatasources[firebaseDatasourceName] = record
                  }
                  return getVariableValue(
                    {
                      source: {
                        type: VariableSourceType.collection,
                        collection: { name, collectionId },
                        selector: { constant: recordId },
                        fieldName,
                        query,
                        transforms,
                      },
                    },
                    toSet,
                    initialSet,
                    listParams
                  )
                } else {
                  return convertValue(variable, getVariableValue, transforms)
                }
              }
              return null
            case VariableSourceType.component:
              if (componentName && fieldName) {
                const name = screenId + componentName + fieldName
                if (toSet !== undefined) {
                  if (JSON.stringify(variables[name]) !== JSON.stringify(toSet.value)) {
                    variables[name] = toSet.value
                  }
                  return variables[name]
                }
                let variable = variables[name]
                if (variable === undefined) {
                  const fieldValue = findComponent(screenConfig, 'name', componentName)?.[
                    fieldName as keyof ScreenComponent
                  ]
                  if (fieldValue) {
                    if (fieldName === 'text') {
                      const text = fieldValue as LocalizedContent
                      variable = await getVariableValue({ textConstant: text })
                    } else if (fieldName === 'date' || fieldName === 'displayDate') {
                      const date = fieldValue as DateVariable
                      variable = await getVariableValue({ ...date, dateConstant: date.constant })
                    } else if (fieldName === 'value') {
                      const value = fieldValue as NumberVariable
                      variable = await getVariableValue({ ...value, numberConstant: value.constant })
                    }
                  }
                }
                return convertValue(variable, getVariableValue, transforms)
              }
              return null
            default:
              return null
          }
        } else if (textConstant !== undefined) {
          return getTextValue(language, getVariableValue, textConstant)
        } else if (numberConstant !== undefined) {
          const { screenName, showTopBar } = screenConfig
          const tabBar = tabBars.find(el => el.tabs?.find(el => el.screen === screenName))
          const showTopBarValue = await getVariableValue({ ...showTopBar, booleanConstant: showTopBar?.constant })
          return getNumberValue(numberConstant, showTopBarValue, !!tabBar, device)
        } else if (booleanConstant !== undefined) {
          return booleanConstant
        } else if (calendarStyleConstant !== undefined) {
          return calendarStyleConstant
        } else if (colorConstant !== undefined) {
          const color = colorStyles[colorConstant.slice(1) as BrandingColorType]
          return color ? color[theme] : colorConstant
        } else if (urlConstant !== undefined) {
          return urlConstant
        } else if (imageConstant !== undefined) {
          return resources.find(el => el.id === imageConstant.resourceId)
        } else if (videoConstant !== undefined) {
          return resources.find(el => el.id === videoConstant.resourceId)
        } else if (dateConstant !== undefined) {
          return dateConstant === '@today' ? new Date().toISOString() : dateConstant
        } else if (dateTimeConstant !== undefined) {
          return dateTimeConstant === '@now' ? new Date().toISOString() : dateTimeConstant
        } else if (accessLevelConstant !== undefined) {
          return accessLevelConstant
        } else if (arrayConstant !== undefined) {
          const value = []
          for (const el of arrayConstant) {
            value.push(await getVariableValue(el))
          }
          return value
        } else if (stringConstant !== undefined) {
          return stringConstant
        }
        return null
      }
      return null
    }
    return getVariableValue
  }

  const convertValue = async (
    value: any,
    getVariableValue: GetVariableValue,
    transforms?: VariableTransform[],
    property?: CollectionProperty,
    record?: boolean
  ) => {
    const type = property?.type
    const collection = property?.collection

    if (type === ValueType.color) {
      value = await getVariableValue({ colorConstant: value })
    }

    if (type === ValueType.richText && typeof value === 'string') {
      value = marked(value, { async: false }) as string
    }

    if (transforms) {
      for (const el of transforms) {
        const {
          transform,
          fieldName,
          participantsKey,
          participantNameKeys,
          participantImageKey,
          value: transformValue1,
          value2: transformValue2,
          type: transformType,
        } = el
        const value1 = await getVariableValue(transformValue1)
        const value2 = await getVariableValue(transformValue2)
        switch (transform) {
          case VariableTransformTransform.matchesRegexp:
            if (typeof value === 'string' && typeof value1 === 'string') {
              value = new RegExp(value1).test(value)
            }
            break
          case VariableTransformTransform.contains:
            if (typeof value === 'string' || Array.isArray(value)) {
              value = value.includes(value1)
            }
            break
          case VariableTransformTransform.throttle:
            if (typeof value1 === 'number') {
              await new Promise(res => setTimeout(res, value1))
            }
            break
          case VariableTransformTransform.trimWhitespacesAndNewlines:
            if (typeof value === 'string') {
              value = value.trim()
            }
            break
          case VariableTransformTransform.identifier:
            value = record ? value.id : null
            break
          case VariableTransformTransform.exists:
          case VariableTransformTransform.isNotEmpty:
            value = record ? !!value.id : !!value || value === 0
            break
          case VariableTransformTransform.isEmpty:
            value = record ? !value.id : !value
            break
          case VariableTransformTransform.boolNot:
            value = !value
            break
          case VariableTransformTransform.anyTrue:
            if (Array.isArray(value)) {
              value = value.some((el: any) => !!el)
            }
            break
          case VariableTransformTransform.allTrue:
            if (Array.isArray(value)) {
              value = value.every((el: any) => !!el)
            }
            break
          case VariableTransformTransform.conditionalValue:
            value = value ? value1 : value2
            break
          case VariableTransformTransform.formatTimeAgo:
            if (typeof value === 'string') {
              value = format(value)
            }
            break
          case VariableTransformTransform.formatTimeInterval:
            if (typeof value === 'number') {
              const fullTime = new Date(value * 1000).toISOString().slice(11, 19)
              value = fullTime.startsWith('00:')
                ? fullTime.slice(3, 8).startsWith('0')
                  ? fullTime.slice(4, 8)
                  : fullTime.slice(3, 8)
                : fullTime.startsWith('0')
                ? fullTime.slice(1)
                : fullTime
            }
            break
          case VariableTransformTransform.compareEqual:
          case VariableTransformTransform.compareNotEqual:
          case VariableTransformTransform.compareGreater:
          case VariableTransformTransform.compareLess:
          case VariableTransformTransform.compareGreaterOrEqual:
          case VariableTransformTransform.compareLessOrEqual:
            value =
              transform === VariableTransformTransform.compareEqual
                ? value === value1
                : transform === VariableTransformTransform.compareNotEqual
                ? value !== value1
                : transform === VariableTransformTransform.compareGreater
                ? value > value1
                : transform === VariableTransformTransform.compareLess
                ? value < value1
                : transform === VariableTransformTransform.compareGreaterOrEqual
                ? value >= value1
                : value <= value1
            break
          case VariableTransformTransform.add:
          case VariableTransformTransform.sub:
          case VariableTransformTransform.mult:
          case VariableTransformTransform.div:
            if (typeof value === 'number' && typeof value1 === 'number') {
              value =
                transform === VariableTransformTransform.add
                  ? value + value1
                  : transform === VariableTransformTransform.sub
                  ? value - value1
                  : transform === VariableTransformTransform.mult
                  ? value * value1
                  : value / value1
            }
            break
          case VariableTransformTransform.cast:
            if (transformType === ValueType.string) {
              value = String(value)
            } else if (transformType === ValueType.number) {
              value = Number(value)
            } else if (transformType === ValueType.boolean) {
              value = Boolean(value)
            }
            break
          case VariableTransformTransform.field:
            if (fieldName) {
              if (type === ValueType.record && collection) {
                const { collectionId, recordId } = extractRecordRef(value)
                value = await getVariableValue({
                  source: {
                    type: VariableSourceType.collection,
                    collection: { ...collection, collectionId },
                    selector: { constant: recordId },
                    fieldName,
                  },
                })
              } else {
                value = value[fieldName]
              }
            }
            break
          case VariableTransformTransform.conversationTitle:
          case VariableTransformTransform.conversationImage:
            if (fieldName && value[fieldName]) {
              value = value[fieldName]
            } else if (
              value &&
              participantsKey &&
              value[participantsKey] &&
              value[participantsKey].length === 2 &&
              participantNameKeys
            ) {
              const currentUserId = await getVariableValue({
                source: { type: VariableSourceType.globalVariable, variableName: 'currentUserId' },
              })
              const participantId = value[participantsKey].find((el: string) => el !== currentUserId)
              if (transform === VariableTransformTransform.conversationTitle) {
                value = await getParticipantFullName(participantId, participantNameKeys, getVariableValue)
              } else if (participantImageKey) {
                value = await getVariableValue({
                  source: {
                    type: VariableSourceType.collection,
                    collection: { name: profilesId },
                    selector: { constant: participantId },
                    fieldName: participantImageKey,
                  },
                })
                if (!value) {
                  value = await createAvatar(participantId, participantNameKeys, getVariableValue)
                }
              }
            } else {
              value = ''
            }
            break
        }
      }
    }

    return value
  }

  const getFirebaseDatasourceRecord = async (collectionId: string, recordId: string) => {
    const firebaseDatasourceName = `${collectionId}/${recordId}`
    if (!firebaseDatasources[firebaseDatasourceName]) {
      const set = (record: CMSCollectionRecord) => (firebaseDatasources[firebaseDatasourceName] = record)
      await getRecord(set, collectionId, recordId)
    }
    return firebaseDatasources[firebaseDatasourceName]
  }

  const getRecord = (set: (record: CMSCollectionRecord) => void, collectionId: string, recordId: string) =>
    new Promise(resolve => {
      const setRecord = async (record: CMSCollectionRecord) => {
        await set(record)
        resolve(true)
      }
      CMSService.getRecord(id, collectionId, recordId, true)
        .then(res => setRecord(res.data || {}))
        .catch(err => {
          toast(err)
          setRecord({})
        })
    })

  const getRecords = (
    set: (records: CMSCollectionRecord[]) => void,
    collectionId: string,
    queryString: string,
    limit?: number,
    ids?: string[]
  ) =>
    new Promise(async resolve => {
      const setRecords = async (records: CMSCollectionRecord[]) => {
        await set(records)
        resolve(true)
      }
      CMSService.getRecords(id, collectionId, queryString, limit, ids, true)
        .then(res => setRecords(res.data || []))
        .catch(err => {
          toast(err, true)
          setRecords([])
        })
    })

  const setLocalVariables: SetLocalVariables = async (getVariableValue, localVariables) => {
    for (const el of localVariables) {
      const { name, variable, type, collection } = el
      if (type === ValueType.record && collection) {
        const value = await getVariableValue(
          { source: { type: VariableSourceType.collection, collection } },
          undefined,
          true
        )
        await getVariableValue(
          { source: { type: VariableSourceType.localVariable, variableName: name } },
          { value },
          true
        )
      } else if (
        type === ValueType.image ||
        type === ValueType.video ||
        type === ValueType.audio ||
        type === ValueType.file
      ) {
        const value = await getVariableValue(
          { source: { type: VariableSourceType.collection, collection: { name: assetsId } } },
          undefined,
          true
        )
        await getVariableValue(
          { source: { type: VariableSourceType.localVariable, variableName: name } },
          { value },
          true
        )
      } else if (variable) {
        const value = await getVariableValue(variable, undefined, true)
        await getVariableValue(
          { source: { type: VariableSourceType.localVariable, variableName: name } },
          { value },
          true
        )
      }
    }
  }

  return { getVariable, setLocalVariables }
}

export const collectionPrefix = 'collection://'

export const extractRecordRef = (recordRef: string) => {
  const segments = recordRef.replace(collectionPrefix, '').split('/')
  const collectionId = segments.slice(0, -1).join('/')
  const recordId = segments.slice(-1)[0]
  return { collectionId, recordId }
}

const getCurrentCollectionId = async (collection: CollectionVariable, getVariableValue: GetVariableValue) => {
  const { collectionId, name, params } = collection
  if (collectionId) {
    return collectionId
  } else {
    let collectionId = name
    if (params) {
      for (const el of Object.keys(params)) {
        const param = params[el]
        const paramValue = await getVariableValue({ ...param, stringConstant: param.constant })
        if (paramValue) {
          collectionId = collectionId.replace(`{${el}}`, paramValue)
        }
      }
    }
    return collectionId
  }
}

export const queryToString = async (
  getVariableValue?: GetVariableValue,
  query?: TableQuery,
  onlyPublishedRecords?: boolean
) => {
  const filters: {
    fieldPath: string
    opStr: firebase.firestore.WhereFilterOp
    value: any
  }[] = []
  const ordered: {
    fieldPath: string
    direction: firebase.firestore.OrderByDirection
  }[] = []
  if (query?.filters) {
    for (const el of query.filters) {
      const value = await getVariableValue?.(el.value)
      if (value !== '' && value !== null && value !== undefined) {
        const opStr = operators[el.operator]
        filters.push({ fieldPath: el.field, opStr, value })
        if (onlyPublishedRecords && needOrderByOperators.includes(opStr)) {
          ordered.push({ fieldPath: el.field, direction: 'desc' })
        }
      }
    }
  }
  if (query?.ordered) {
    for (const el of query.ordered) {
      ordered.push({
        fieldPath: el.field,
        direction: el.order === TableQueryOrder.ascending ? 'asc' : 'desc',
      })
    }
  } else {
    ordered.push({ fieldPath: 'createdAt', direction: 'desc' })
  }
  return JSON.stringify({ filters, ordered })
}

const getParticipantFullName = async (
  participantId: string,
  participantNameKeys: string[],
  getVariableValue: GetVariableValue
) => {
  const fields: string[] = []
  for (const fieldName of participantNameKeys) {
    fields.push(
      await getVariableValue({
        source: {
          type: VariableSourceType.collection,
          collection: { name: profilesId },
          selector: { constant: participantId },
          fieldName,
        },
      })
    )
  }
  return fields.join(' ')
}

const createAvatar = async (
  participantId: string,
  participantNameKeys: string[],
  getVariableValue: GetVariableValue
) => {
  const randomColor = () => '#' + (0x1000000 | (Math.random() * 0xffffff)).toString(16).substr(1, 6)
  const canvas = document.createElement('canvas')
  canvas.width = 100
  canvas.height = 100
  const context = canvas.getContext('2d')
  if (context) {
    context.fillStyle = randomColor()
    context.beginPath()
    context.ellipse(canvas.width / 2, canvas.height / 2, canvas.width / 2, canvas.height / 2, 0, 0, Math.PI * 2)
    context.fill()
    context.font = '60px serif'
    context.fillStyle = randomColor()
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    const initials = (await getParticipantFullName(participantId, participantNameKeys, getVariableValue))
      .split(' ')
      .map(el => el[0] || '')
      .join('')
    context.fillText(initials, canvas.width / 2, canvas.height / 2)
  }
  return canvas.toDataURL()
}

export const setInputParameters = async (inputParameters: ScreenParameter[], getVariableValue: GetVariableValue) => {
  const inputParameter = inputParameters.find(el => el.required)
  if (inputParameter) {
    const { parameter, type, collection } = inputParameter
    if (type === ValueType.record && collection) {
      const value = await getVariableValue(
        {
          source: {
            type: VariableSourceType.collection,
            collection,
            transforms: [{ transform: VariableTransformTransform.first }],
          },
        },
        undefined,
        true
      )
      await getVariableValue(
        { source: { type: VariableSourceType.localVariable, variableName: parameter } },
        { value },
        true
      )
    } else {
      await getVariableValue(
        { source: { type: VariableSourceType.localVariable, variableName: parameter } },
        { value: null },
        true
      )
    }
  }
}
