import { GetVariableValue } from 'hooks'
import { BackgroundGradient, BackgroundGradientDirection, ColorVariable, ImageConstant } from 'utils'

export const setColors = async (
  element: HTMLElement,
  getVariableValue: GetVariableValue,
  backgroundColor?: ColorVariable,
  backgroundGradient?: BackgroundGradient,
  backgroundPattern?: ImageConstant,
  textColor?: ColorVariable,
  borderColor?: ColorVariable
) => {
  if (backgroundColor) {
    element.style.backgroundColor = await getVariableValue({
      ...backgroundColor,
      colorConstant: backgroundColor.constant,
    })
  }

  if (backgroundGradient) {
    const { direction, start, end } = backgroundGradient
    const startColor = await getVariableValue({ colorConstant: start })
    const endColor = await getVariableValue({ colorConstant: end })
    element.style.backgroundImage =
      direction === BackgroundGradientDirection.radial
        ? `radial-gradient(${startColor}, ${endColor})`
        : `linear-gradient(to ${
            direction === BackgroundGradientDirection.vertical ? 'bottom' : 'right'
          }, ${startColor}, ${endColor})`
  }

  if (backgroundPattern) {
    const mediaValue = await getVariableValue({ imageConstant: backgroundPattern })
    const url = mediaValue?.url || ''
    element.style.backgroundImage = `url(${url})`
    element.style.backgroundPosition = 'center'
    element.style.backgroundSize = 'cover'
  }

  if (borderColor) {
    element.style.borderColor = await getVariableValue({ ...borderColor, colorConstant: borderColor.constant })
  }

  if (textColor) {
    element.style.color = await getVariableValue({ ...textColor, colorConstant: textColor.constant })
  }
}
