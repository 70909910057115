import { GetVariableValue } from 'hooks'
import { NumberVariable, progressIndicatorAttribute, progressPassedAttribute } from 'utils'

const currentSlide: { [key: string]: number } = {}

const slideClickEvent = 'slideClickEvent'
export const carouselHandler = async (
  carousel: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: NumberVariable
) => {
  const slides = Array.from(carousel.querySelectorAll(':scope > div')) as HTMLElement[]
  const progressParts = Array.from(
    carousel.querySelectorAll(`:scope > i > svg > rect[${progressIndicatorAttribute}]`)
  ) as SVGRectElement[]
  if (slides.length && progressParts.length) {
    const lastIndex = slides.length - 1
    slides.forEach((el, i) =>
      el.addEventListener(slideClickEvent, () => {
        currentSlide[carousel.id] = i
        slides.slice(0, i).forEach(el => (el.hidden = true))
        slides.slice(i + 1).forEach(el => (el.hidden = true))
        slides[i].removeAttribute('hidden')
        progressParts.slice(0, i).forEach(el => el.setAttribute(progressPassedAttribute, ''))
        progressParts.slice(i + 1).forEach(el => el.removeAttribute(progressPassedAttribute))
        progressParts[i].setAttribute(progressPassedAttribute, '')
      })
    )
    const valueValue = await getVariableValue({ ...value, numberConstant: value?.constant })
    const currentValue = valueValue || 0
    slides[currentSlide[carousel.id] || (currentValue > lastIndex ? 0 : currentValue)].dispatchEvent(
      new Event(slideClickEvent)
    )
  }
}

export const moveSlide = (selectedComponent: HTMLElement, next?: boolean) => {
  const slides = Array.from(selectedComponent.querySelectorAll(':scope > div')) as HTMLElement[]
  const activeIndex = slides.findIndex(el => !el.hidden)
  slides[next ? activeIndex + 1 : activeIndex - 1]?.dispatchEvent(new Event(slideClickEvent))
}
