import { Box } from '@mui/material'
import { marked } from 'marked'
import { memo, ReactElement, useEffect, useState } from 'react'

import * as styled from './FieldTypes.styled'

import {
  AppIconInput,
  Button,
  ButtonColor,
  CoreColorInput,
  Icon,
  LaunchScreenBackgroundInput,
  LaunchScreenIconInput,
  Name,
} from 'components'
import { collectionPrefix } from 'hooks'
import { CMSCollectionRecord } from 'services/cms'
import { DataObject, firstLetterUpperCase, isUrl, ValueType } from 'utils'
import {
  AvatarInput,
  BooleanInput,
  CoordinateInput,
  DateInput,
  FileInput,
  FolderInput,
  LogicItemField,
  MultipleSelect,
  NumberInput,
  PasswordInput,
  PhoneNumberInput,
  Preview,
  RefField,
  RichText,
  Screenshots,
  SimpleArrayInput,
  SimpleJsonInput,
  SimpleObjectInput,
  SimpleSelect,
  SimpleTsInput,
  StringInput,
  SubCollectionField,
  VariableInput,
} from './components'
import { FieldProps } from './types'

interface FieldWrapperProps extends FieldProps {
  children: (props: FieldProps) => ReactElement
  richText?: boolean
  password?: boolean
  folder?: boolean
  select?: boolean
}

export const FieldWrapper = memo((props: FieldWrapperProps) => {
  const [value, onChange] = useState(props.value)

  useEffect(() => {
    onChange(props.value)
  }, [props.value])

  useEffect(() => {
    if (value !== props.value) {
      const debounceFn = setTimeout(() => props.onChange(value), 600)
      return () => clearTimeout(debounceFn)
    }
  }, [value])

  return (
    <>
      <styled.Field
        onClick={props.onClick || (e => props.richText && e.preventDefault())}
        $isPlaceholder={!props.value}
        $smallWidth={props.smallWidth}
        $ai={props.ai}
        $editByModal={props.editByModal}
        $password={props.password}
        $folder={props.folder}
        $select={props.select}
        control={props.children({ ...props, value, onChange })}
        label={
          !props.hideLabel ? (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                {props.label}
                {props.helpText && <styled.HelpText>{props.helpText}</styled.HelpText>}
              </Box>
              {!!props.onAddBillingAccount && (
                <Button color={ButtonColor.OUTLINED} disabled={props.disabled} onClick={props.onAddBillingAccount}>
                  Add billing account
                </Button>
              )}
            </Box>
          ) : undefined
        }
        labelPlacement="top"
      />
      {!!props.onRun && (
        <styled.RunButton>
          <Button color={ButtonColor.OUTLINED} disabled={props.disabled} onClick={props.onRun}>
            Run
          </Button>
        </styled.RunButton>
      )}
      {!!props.error && (
        <styled.ErrorMessage>
          <Icon name={Name.RIGHT_SIDEBAR_INFO} />
          {props.helperText}
        </styled.ErrorMessage>
      )}
    </>
  )
})

export const fieldTypes = [
  {
    type: ValueType.accessLevel,
    iconName: Name.FIELD_TYPES_ACCESS_LEVEL,
    geTypeErrorMessage: (value: any) => typeof value !== 'string' && 'Must be a string type!',
    component: (props: FieldProps) => (
      <FieldWrapper
        {...props}
        select={!!props.optionsToSelect}
        children={props => (props.optionsToSelect ? <SimpleSelect {...props} /> : <StringInput {...props} />)}
      />
    ),
    forTable: (value: any) => <styled.ThreeRows>{value}</styled.ThreeRows>,
  },
  {
    type: ValueType.color,
    iconName: Name.FIELD_TYPES_STRING,
    geTypeErrorMessage: (value: any) => typeof value !== 'string' && 'Must be a string type!',
    component: (props: FieldProps) => (
      <FieldWrapper
        {...props}
        select={!!props.optionsToSelect}
        children={props => (props.optionsToSelect ? <SimpleSelect {...props} /> : <StringInput {...props} />)}
      />
    ),
    forTable: (value: any) => <styled.ThreeRows>{value}</styled.ThreeRows>,
  },
  {
    type: ValueType.string,
    iconName: Name.FIELD_TYPES_STRING,
    geTypeErrorMessage: (value: any) => typeof value !== 'string' && 'Must be a string type!',
    component: (props: FieldProps) => (
      <FieldWrapper
        {...props}
        select={!!props.optionsToSelect}
        children={props => (props.optionsToSelect ? <SimpleSelect {...props} /> : <StringInput {...props} />)}
      />
    ),
    forTable: (value: any) => <styled.ThreeRows>{value}</styled.ThreeRows>,
  },
  {
    type: ValueType.number,
    iconName: Name.FIELD_TYPES_NUMBER,
    geTypeErrorMessage: (value: any) => typeof value !== 'number' && 'Must be a number type!',
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <NumberInput {...props} />} />,
    forTable: (value: any) => value,
  },
  {
    type: ValueType.boolean,
    iconName: Name.FIELD_TYPES_BOOLEAN,
    geTypeErrorMessage: (value: any) => typeof value !== 'boolean' && 'Must be a boolean type!',
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <BooleanInput {...props} />} />,
    forTable: (value: any) => (value === true || value === false) && String(value),
  },
  {
    type: ValueType.url,
    iconName: Name.FIELD_TYPES_URL,
    geTypeErrorMessage: (value: any) => {
      try {
        if (!isUrl(value)) {
          return 'This is not valid URL!'
        }
      } catch {
        return 'This is not valid URL!'
      }
      return false
    },
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <StringInput {...props} />} />,
    forTable: (value: any) => (
      <styled.Nowrap>
        {value && (
          <a href={value} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
            {value}
          </a>
        )}
      </styled.Nowrap>
    ),
  },
  {
    type: ValueType.image,
    iconName: Name.FIELD_TYPES_IMAGE,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    component: (props: FieldProps) => <RefField {...props} resourceType={ValueType.image} />,
    forTable: (value: any) => value && <Preview asset={value} small />,
  },
  {
    type: ValueType.video,
    iconName: Name.FIELD_TYPES_VIDEO,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    component: (props: FieldProps) => <RefField {...props} resourceType={ValueType.video} />,
    forTable: (value: any) => value && <Preview asset={value} small />,
  },
  {
    type: ValueType.audio,
    iconName: Name.FIELD_TYPES_AUDIO,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    component: (props: FieldProps) => <RefField {...props} resourceType={ValueType.audio} />,
    forTable: (value: any) => value && <Preview asset={value} small />,
  },
  {
    type: ValueType.file,
    iconName: Name.FIELD_TYPES_FILE,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    component: (props: FieldProps) => <RefField {...props} resourceType={ValueType.file} />,
    forTable: (value: any) => value && <Preview asset={value} small />,
  },
  {
    type: ValueType.array,
    iconName: Name.FIELD_TYPES_ARRAY,
    geTypeErrorMessage: (value: any) => !Array.isArray(value) && 'Must be an array type!',
    component: (props: FieldProps) =>
      props.optionsToSelect ? (
        <FieldWrapper {...props} select children={props => <MultipleSelect {...props} />} />
      ) : props.accept === ValueType.accessLevel ||
        props.accept === ValueType.color ||
        props.accept === ValueType.string ||
        props.accept === ValueType.number ||
        props.accept === ValueType.boolean ||
        props.accept === ValueType.url ? (
        <FieldWrapper {...props} select children={props => <SimpleArrayInput {...props} />} />
      ) : props.accept === ValueType.image ||
        props.accept === ValueType.video ||
        props.accept === ValueType.audio ||
        props.accept === ValueType.file ? (
        <RefField {...props} multiple resourceType={props.accept} />
      ) : props.accept === ValueType.record ? (
        <RefField {...props} multiple />
      ) : props.accept === ValueType.coordinate ? (
        <FieldWrapper {...props} children={props => <CoordinateInput {...props} multiple />} />
      ) : (
        <>{props.accept} array</>
      ),
    forTable: (value: any) => `[${value?.length || 0} items]`,
  },
  {
    type: ValueType.record,
    iconName: Name.FIELD_TYPES_REFERENCE,
    geTypeErrorMessage: (value: any) => !value?.startsWith(collectionPrefix) && 'Invalid record!',
    component: (props: FieldProps) => <RefField {...props} />,
    forTable: (value: any) => <styled.Nowrap>{value}</styled.Nowrap>,
  },
  {
    type: ValueType.date,
    iconName: Name.FIELD_TYPES_DATE,
    geTypeErrorMessage: (value: any) => {
      try {
        if (isNaN(+new Date(value))) {
          return 'Invalid date!'
        }
      } catch {
        return 'Invalid date!'
      }
      return false
    },
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <DateInput {...props} />} />,
    forTable: (value: any) => <Box sx={{ whiteSpace: 'nowrap' }}>{value}</Box>,
  },
  {
    type: ValueType.dateTime,
    iconName: Name.FIELD_TYPES_DATE_TIME,
    geTypeErrorMessage: (value: any) => {
      try {
        if (isNaN(+new Date(value))) {
          return 'Invalid date!'
        }
      } catch {
        return 'Invalid date!'
      }
      return false
    },
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <DateInput dateTime {...props} />} />,
    forTable: (value: any) => <Box sx={{ whiteSpace: 'nowrap' }}>{value && value.slice(0, 16)}</Box>,
  },
  {
    type: ValueType.richText,
    iconName: Name.FIELD_TYPES_RICH_TEXT,
    geTypeErrorMessage: (value: any) => typeof value !== 'string' && 'Must be a string type!',
    component: (props: FieldProps) => <FieldWrapper {...props} richText children={props => <RichText {...props} />} />,
    forTable: (value: any) => (
      <styled.ThreeRows>
        {value && <div dangerouslySetInnerHTML={{ __html: marked(value, { async: false }) as string }} />}
      </styled.ThreeRows>
    ),
  },
  {
    type: ValueType.json,
    iconName: Name.FIELD_TYPES_JSON,
    geTypeErrorMessage: (value: any) => {
      try {
        JSON.parse(value)
      } catch {
        return 'This is not valid JSON!'
      }
      return false
    },
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <SimpleJsonInput {...props} />} />,
    forTable: (value: any) => <styled.ThreeRows>{value}</styled.ThreeRows>,
  },
  {
    type: ValueType.keyValueMap,
    iconName: Name.FIELD_TYPES_KEY_VALUE_MAP,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    getDefaultValue: (dataObjects: DataObject[], dataObject?: string) => {
      const obj: CMSCollectionRecord = {}
      const properties = dataObjects.find(el => el.name === dataObject)?.properties
      if (properties) {
        properties.forEach(property => {
          obj[property.name] = getField(property.type)?.getDefaultValue?.(dataObjects, property.dataObject) || null
        })
      }
      return obj
    },
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <SimpleObjectInput {...props} />} />,
    forTable: (value: any) => <styled.ThreeRows>{value && JSON.stringify(value)}</styled.ThreeRows>,
  },
  {
    type: ValueType.coordinate,
    iconName: Name.FIELD_TYPES_COORDINATE,
    geTypeErrorMessage: (value: any) => typeof value !== 'object' && 'Must be an object type!',
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <CoordinateInput {...props} />} />,
    forTable: (value: any) => (
      <Box sx={{ whiteSpace: 'nowrap' }}>{value && `${value.latitude} ${value.longitude}`}</Box>
    ),
  },
  {
    type: ValueType.vector,
    iconName: Name.FIELD_TYPES_STRING,
    geTypeErrorMessage: (value: any) => typeof value !== 'string' && 'Must be a string type!',
    component: (props: FieldProps) => (
      <FieldWrapper
        {...props}
        select={!!props.optionsToSelect}
        children={props => (props.optionsToSelect ? <SimpleSelect {...props} /> : <StringInput {...props} />)}
      />
    ),
    forTable: (value: any) => <styled.ThreeRows>{value}</styled.ThreeRows>,
  },
  {
    type: ValueType.password,
    component: (props: FieldProps) => (
      <FieldWrapper {...props} password children={props => <PasswordInput {...props} />} />
    ),
    local: true,
    forTable: () => '',
  },
  {
    type: ValueType.phoneNumber,
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <PhoneNumberInput {...props} />} />,
    local: true,
    forTable: () => '',
  },
  {
    type: ValueType.logicItem,
    component: (props: FieldProps) => <LogicItemField {...props} />,
    local: true,
    forTable: () => '',
  },
  {
    type: ValueType.subCollection,
    component: (props: FieldProps) => <SubCollectionField {...props} />,
    local: true,
    forTable: () => '',
  },
  {
    type: ValueType.typescript,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <SimpleTsInput {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.fileInput,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <FileInput {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.variable,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={() => <VariableInput {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.folder,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} folder children={props => <FolderInput {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.screenshots,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <Screenshots {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.avatar,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={props => <AvatarInput {...props} />} />,
    forTable: () => '',
  },
  {
    type: ValueType.appIcon,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={() => <AppIconInput big />} />,
    forTable: () => '',
  },
  {
    type: ValueType.coreColor,
    local: true,
    component: (props: FieldProps) => (
      <FieldWrapper {...props} children={props => <CoreColorInput big colorType={props.value} />} />
    ),
    forTable: () => '',
  },
  {
    type: ValueType.launchScreenIcon,
    local: true,
    component: (props: FieldProps) => (
      <FieldWrapper {...props} children={props => <LaunchScreenIconInput big iconName={props.value} />} />
    ),
    forTable: () => '',
  },
  {
    type: ValueType.launchScreenBackground,
    local: true,
    component: (props: FieldProps) => <FieldWrapper {...props} children={() => <LaunchScreenBackgroundInput big />} />,
    forTable: () => '',
  },
]

export const getField = (type: ValueType) => fieldTypes.find(el => el.type === type)

export const getFieldIcon = (type: ValueType) => getField(type)?.iconName || Name.RIGHT_SIDEBAR_DEFAULT_ICON

export const dataTypes = fieldTypes
  .filter(el => !el.local)
  .map(el => ({ value: el.type, label: firstLetterUpperCase(el.type), iconName: el.iconName }))

export const acceptDataTypesValues = dataTypes.filter(
  el =>
    el.value !== ValueType.array &&
    el.value !== ValueType.richText &&
    el.value !== ValueType.json &&
    el.value !== ValueType.date &&
    el.value !== ValueType.dateTime
)

interface AnyFieldWrapperProps {
  control: ReactElement
}

export const AnyFieldWrapper: React.FC<AnyFieldWrapperProps> = ({ control }) => (
  <styled.Field $noMargin control={control} label={null} />
)
